import { Pipe, PipeTransform } from '@angular/core';
import { IReportService } from '../models/report-service.interface';
import { ReportShipmentService } from '../services/report-shipment.service';

@Pipe({
  name: 'services',
  pure: false,
})
export class ServicesPipe implements PipeTransform {
  private serviceTypes: IReportService[] = [];

  public constructor(private reportShipmentService: ReportShipmentService) {
    // Load service types when pipe is created
    this.loadServiceTypes();
  }

  public transform(value: string): string {
    // If no value provided, return empty string
    if (!value) {
      return '';
    }

    // Find matching service and return its description
    const service = this.serviceTypes.find(s => s.ServiceCode === value);
    return service ? service.Description : value;
  }

  private loadServiceTypes(): void {
    this.reportShipmentService.getReportServices()
      .subscribe({
        next: services => {
          this.serviceTypes = services;
        },
        error: err => {
          console.error('Error loading service types:', err);
        },
      });
  }
}
