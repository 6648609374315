import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import moment from 'moment';
import { AppState } from '../app.state';
import { SharedService } from '../core/services/shared.service';
import { SpinnerService } from '../core/services/spinner/spinner.service';
import { S3FileDownloaderComponent } from '../shared/components/s3-file-downloader/s3-file-downloader.component';
import { NotificationType } from '../shared/models/notification-type';
import { ErrorHandlerService } from '../shared/services/error-handler/error-handler.service';
import { FileService } from '../shared/services/file/file.service';
import { NotificationService } from '../shared/services/notification/notification.service';
import { User } from '../shared/services/user/models/user.model';
import { UserService } from '../shared/services/user/user.service';
import { UtilityService } from '../shared/services/utility/utility.service';
import { ICarrierService } from '../ship/models/carrier-service.interface';
import { IWorldShipShipmentCsvDE } from './models/world-ship-shipment-csv-de.interface';
import { IWorldShipShipmentCsv } from './models/world-ship-shipment-csv.interface';
import { IWorldShipShipment } from './models/world-ship-shipment.interface';
import { WorldShipUploadService } from './services/world-ship-upload.service';
import { environment } from 'environments/environment';

@Component({
    selector: 'upsc-world-ship-upload',
    templateUrl: './world-ship-upload.component.html',
    styleUrls: ['./world-ship-upload.component.scss'],
})
export class WorldShipUploadComponent implements OnInit {
    @ViewChild('form', { static: true }) public form: ElementRef;
    @ViewChild('worldShipInstructionDownloader', { static: true }) public worldShipInstructionDownloader: S3FileDownloaderComponent;

    public formGroup: UntypedFormGroup;
    public user: User;
    public files: FileList;
    public fileName: string;
    public worldShipShipments: IWorldShipShipment[];
    public isWorldShipInstructionDownloading = false;
    public serviceTypes: ICarrierService[];
    public isEUUser: boolean;
    public userCountryCode: string;
    public isInProgress = false;

    public faqFileName: string = 'UPS_WorldShip_Upload_FAQ.pdf';
    public faqFileBucket: string = 'members-pdf.upsc';
    public instructionsFileName: string = 'worldship_upload_instruction.pdf';
    public instructionsFileBucket: string = 'members-pdf.upsc';

    protected readonly environment = environment;

    public constructor(private formBuilder: UntypedFormBuilder,
                       private sharedService: SharedService,
                       private fileService: FileService,
                       private userService: UserService,
                       private spinnerService: SpinnerService,
                       private utilityService: UtilityService,
                       private errorHandlerService: ErrorHandlerService,
                       private notificationService: NotificationService,
                       private translateService: TranslateService,
                       private worldShipUploadService: WorldShipUploadService,
                       private readonly appState: AppState,
    ) {
        this.user = this.appState.user$();
        this.userCountryCode = this.user?.CountryCode;
        this.isEUUser = this.userService.isEUUser(this.user?.CountryCode);

        this.worldShipUploadService.getServiceTypes('UPS')
            .subscribe(
                serviceTypes => this.handleGetServiceTypesSuccess(serviceTypes),
                err => this.handleGetServiceTypesFailure(err),
            );
    }

    public ngOnInit() {
        this.formGroup = this.formBuilder.group({
            reference: ['', Validators.compose([Validators.required])],
            shipments: this.formBuilder.group({
                worldShipShipments: this.formBuilder.array([]),
            }),
        });

        if (this.isEUUser) {
            this.formGroup.controls.reference.setValue('3');
        }

        this.setFileNamesBasedOnCountry();
    }

    public onFormSubmitted(event, form) {
        event.preventDefault();

        // Map service type values to the original service names.
        const hasShipments = form && form.shipments && form.shipments.worldShipShipments && form.shipments.worldShipShipments.length;
        if (hasShipments) {
            Array.from(form.shipments.worldShipShipments).forEach(
                (shipment: any) => {
                    const targetServiceType = this.serviceTypes.find(
                        (serviceType) => {
                            return shipment.ServiceType === _.startCase(_.toLower(serviceType.ServiceName));
                        });

                    if (targetServiceType) {
                        shipment.ServiceType = targetServiceType.ServiceName;
                    }
                    shipment.Exceptions = []; // clear old exceptions before submitting. old exception causing errors[MV3-4014][MV3-4013].
                },
            );
        }

        this.isInProgress = true;
        this.spinnerService.show();
        this.worldShipUploadService.saveWorldShipShipments(form)
            .subscribe(
                response => this.handleSaveWorldShipShipmentsSuccess(),
                err => this.handleSaveWorldShipShipmentsFailure(err),
            );
    }

    /**
     * The event fired when a user resets the form.
     *
     * @param event
     */
    public resetForm(event) {
        if (event) {
            event.preventDefault();
        }

        this.formGroup.reset();
        const fileInputs = Array.from(this.form.nativeElement.querySelectorAll('input[type=file]'));
        fileInputs.forEach((input: any) => input.value = '');
        this.fileName = null;

        this.clearExistingShipments();
    }

    public onFileSelectionChanged(files: FileList) {
        this.files = files;
        if (!this.files || this.files.length === 0) {
            return;
        }

        const fileType = this.files[0].name.split('.').pop();
        if (fileType.toLowerCase() !== 'csv') {
            this.files = null;
            this.notificationService.notify('Only CSV is supported.', 'File Type Not Supported', NotificationType.WARNING);
            return;
        }

        // TODO: for UK user (username starting with GB), check if login username matches the PackageReference1 in CSV.
        this.convertCsvToJson(this.user);
    }

    public isFormValid() {
        const shipmentsArray = <UntypedFormArray>((<UntypedFormGroup>this.formGroup.controls.shipments).controls.worldShipShipments);
        const isShipmentsEmpty = !shipmentsArray || shipmentsArray.length === 0;

        return this.formGroup.valid && !isShipmentsEmpty;
    }

    public removeFile(event) {
        event.preventDefault();

        const fileInputs = Array.from(this.form.nativeElement.querySelectorAll('input[type=file]'));
        fileInputs.forEach(input => input['value'] = '');
        this.fileName = null;

        this.clearExistingShipments();
    }

    public downloadWorldShipInstruction(event) {
        event.preventDefault();

        this.isWorldShipInstructionDownloading = true;
        this.worldShipInstructionDownloader.downloadFile(event);
    }

    public onWorldShipInstructionDownloadEnded() {
        this.isWorldShipInstructionDownloading = false;
    }

    private handleGetServiceTypesSuccess(serviceTypes: ICarrierService[]) {
        this.serviceTypes = serviceTypes;
    }

    private handleGetServiceTypesFailure(err) {
        // TODO: check where to get the carrier code.
        this.worldShipUploadService.getServiceTypesFixed('UPS')
            .subscribe(
                serviceTypes => this.handleGetServiceTypesFixedSuccess(serviceTypes),
                error => this.handleGetServiceTypesFixedFailure(error),
            );
    }

    private handleGetServiceTypesFixedSuccess(serviceTypes: ICarrierService[]) {
        this.serviceTypes = serviceTypes;
    }

    private handleGetServiceTypesFixedFailure(_err) {
        console.log(_err);
    }

    private initWorldShipShipment(shipment: IWorldShipShipment) {
        if (shipment) {
            return this.formBuilder.group({
                BillToPpiNumber: [shipment.BillToPpiNumber],
                BillToUpsNumber: [shipment.BillToUpsNumber],
                Created: [moment(shipment.Created, 'YYYY-MM-DD').startOf('day').format()],
                Exceptions: [shipment.Exceptions],
                Ref1: [shipment.Ref1],
                Ref2: [shipment.Ref2],
                Ref3: [shipment.Ref3],
                ServiceType: [_.startCase(_.toLower(shipment.ServiceType))],
                ShipperUpsNumber: [shipment.ShipperUpsNumber],
                TrackingNumber: [shipment.TrackingNumber],
                Void: [shipment.Void],
                CompanyName: [shipment.CompanyName],
                PackageWeight: [shipment.PackageWeight]
            });
        }

        return this.formBuilder.group({
            BillToPpiNumber: [''],
            BillToUpsNumber: [''],
            Created: [''],
            Exceptions: [null],
            Ref1: [''],
            Ref2: [''],
            Ref3: [''],
            ServiceType: [''],
            ShipperUpsNumber: [''],
            TrackingNumber: [''],
            Void: [''],
            CompanyName: [''],
        });
    }

    private handleSaveWorldShipShipmentsSuccess() {
        this.spinnerService.hide();
        this.isInProgress = false;

        const message = this.translateService.instant('worldShipUpload.uploadSuccess');
        this.notificationService.notify(message, null, NotificationType.SUCCESS);

        this.resetForm(null);
    }

    private handleSaveWorldShipShipmentsFailure(err) {
        this.spinnerService.hide();
        const json = err.error.Data;

        if (!json.WorldshipShipments) {
            this.notificationService.notify(this.errorHandlerService.getHttpErrorMessage(err), this.translateService.instant('worldShipUpload.uploadFailedTitle'), NotificationType.ERROR);
            return;
        }

        this.notificationService.notify(this.translateService.instant('worldShipUpload.uploadFailedMsg'), this.translateService.instant('worldShipUpload.uploadFailedTitle'), NotificationType.ERROR);

        if (json) {
            this.worldShipShipments = json.WorldshipShipments.map(
                (shipment) => {
                    shipment.Created = moment(shipment.Created, 'YYYYMMDD').format('YYYY-MM-DD');
                    return shipment;
                },
            );
        } else {
            this.worldShipShipments.map(
                (shipment) => {
                    shipment.Created = moment(shipment.Created, 'YYYYMMDD').format('YYYY-MM-DD');
                    return shipment;
                },
            );
        }

        const controls = <UntypedFormArray>((<UntypedFormGroup>this.formGroup.controls.shipments).controls.worldShipShipments);
        while (controls.length) {
            controls.removeAt(0);
        }

        this.worldShipShipments.forEach(
            (shipment) => {
                controls.push(this.initWorldShipShipment(shipment));
            },
        );

        this.isInProgress = false;
    }

    private convertCsvToJson(user: User) {
        this.clearExistingShipments();
        this.fileName = this.files[0].name;
        this.fileService.convertCsvToJson<IWorldShipShipmentCsv[] | IWorldShipShipmentCsvDE[]>(this.files[0])
            .subscribe(
                shipments => this.handleConvertCsvToJsonSuccess(shipments, user),
                err => this.handleConvertCsvToJsonFailure(err),
            );
    }

    private clearExistingShipments() {
        this.fileName = null;
        const controls = <UntypedFormArray>((<UntypedFormGroup>this.formGroup.controls.shipments).controls.worldShipShipments);

        this.worldShipShipments = null;

        while (controls.length) {
            controls.removeAt(0);
        }
    }

    private handleConvertCsvToJsonSuccess(shipments: any[], user: User) {
        if (!shipments) {
            return;
        }

        this.refineServiceTypeValues(shipments);
        const selectedReference = this.formGroup.controls.reference.value;
        if (selectedReference) {
            shipments.forEach(
                (shipment: any) => {
                    const properties = [];
                    switch (selectedReference) {
                        case '2':
                            properties.push(...['PackageReference2', 'PaketReferenz2']);
                            break;
                        case '3':
                            properties.push(...['PackageReference3', 'PaketReferenz3']);
                            break;
                        default:
                            break;
                    }

                    if (properties.length) {
                        properties.forEach(
                            (property) => {
                                if (!Object.prototype.hasOwnProperty.call(shipment, property)) {
                                    return true;
                                }

                                if (this.utilityService.isStringEmptyOrNullOrUndefined(shipment[property])) {
                                    shipment[property] = 0;
                                }
                            },
                        );
                    }
                },
            );
        }

        let canViewShipments = true;
        if (this.userService.isGBUser(user)) {
            if (Object.prototype.hasOwnProperty.call(shipments[0], 'PackageReference1')) {
                canViewShipments = user.UserName === shipments[0]['PackageReference1'];
            } else if (Object.prototype.hasOwnProperty.call(shipments[0], 'PaketReferenz1')) {
                canViewShipments = user.UserName === shipments[0]['PaketReferenz1'];
            }
        }

        if (!canViewShipments) {
            this.notificationService.notify('You are not eligible to view this file.', 'User not matched', NotificationType.WARNING);
            return;
        }

        const controls = <UntypedFormArray>((<UntypedFormGroup>this.formGroup.controls.shipments).controls.worldShipShipments);
        this.worldShipShipments = this.worldShipUploadService.refineWorldShipShipments(shipments);
        this.worldShipShipments.forEach(
            (shipment) => {
                controls.push(this.initWorldShipShipment(shipment));
            },
        );
    }

    private handleConvertCsvToJsonFailure(err) {
        // TODO: log it!
        console.error(err);
    }

    private refineServiceTypeValues(shipments: any[]) {
        // [MV3-1919] Allow Express services to be read by non-DE/GB users.
        // [MV3-1594] Switch service type names for DE/GB users.
        if (!this.userService.isDEGBUser(this.user)) {
            return;
        }

        shipments.forEach(
            (shipment: any) => {
                if (_.startCase(_.lowerCase(shipment['ShipmentInformationServiceType'])) === 'Express Saver') {
                    shipment['ShipmentInformationServiceType'] = 'Worldwide Saver';
                }

                if (_.startCase(_.lowerCase(shipment['ShipmentInformationServiceType'])) === 'Express') {
                    shipment['ShipmentInformationServiceType'] = 'Worldwide Express';
                }
            },
        );
        shipments.forEach(
            (shipment: any) => {
                if (_.startCase(_.lowerCase(shipment['SendungsinformationenServiceart'])) === 'Express Saver') {
                    shipment['SendungsinformationenServiceart'] = 'Worldwide Saver';
                }

                if (_.startCase(_.lowerCase(shipment['SendungsinformationenServiceart'])) === 'Express') {
                    shipment['SendungsinformationenServiceart'] = 'Worldwide Express';
                }
            },
        );
    }

    private setFileNamesBasedOnCountry(): void {
        const country = this.user.CountryCode.toUpperCase();
        switch (country) {
          case 'IT':
            this.faqFileName = 'UPS_WorldShip_Upload_FAQ_IT.docx';
            this.faqFileBucket = 'members-docx.upsc';
            break;
          case 'DE':
            this.faqFileName = 'UPS_WorldShip_Upload_FAQ_DE.docx';
            this.faqFileBucket = 'members-docx.upsc';
            break;
          case 'FR':
            this.faqFileName = 'UPS_WorldShip_Upload_FAQ_FR.docx';
            this.faqFileBucket = 'members-docx.upsc';
            break;
          case 'GB':
            this.faqFileName = 'UPS_WorldShip_Upload_FAQ_UK.docx';
            this.faqFileBucket = 'members-docx.upsc';
            this.instructionsFileName = 'worldship_upload_instruction_UK.pdf';
            this.instructionsFileBucket = 'members-pdf.upsc';
            break;
          case 'HK':
          case 'US':
          default:
            this.faqFileName = 'UPS_WorldShip_Upload_FAQ.pdf';
            this.faqFileBucket = 'members-pdf.upsc';
            this.instructionsFileName = 'worldship_upload_instruction.pdf';
            this.instructionsFileBucket = 'members-pdf.upsc';
        }
      }
}
